<template>
    <div class="not-found">
      <h1><span class="error-code">404 - Page Not Found</span></h1>
      <p>The page you're looking for doesn't exist. Here are some helpful links:</p>
      <br/>
     <router-link to="/">Go to Home Page</router-link>
        <router-link to="/services">Services</router-link>
        <router-link to="/contact-us">Contact us</router-link>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    margin: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    align-content: center;
    height: 100vh;
    border-radius: 8px;
  }
  .error-code  {
    font-size: 2em;
    margin-bottom: 20px;
    color: red;
  }
  p {
    font-size: 1.5em;
  }


  a {
    color: #ffff;
    text-decoration: none;
    font-weight: bold;
    background: #42b983;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    text-decoration: none;
  }
  a:hover {
    background-color: rgb(3, 26, 26);
  }
  </style>
  
