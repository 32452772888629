<template>
    <div class="social-buttons">
      <a href="mailto:info@neatfreaks.co.ke" target="_blank" class="social-button google" rel="noopener noreferrer">
    <i class="fab fa-google"></i>
</a>

      <!-- <a href="https://facebook.com" target="_blank" class="social-button facebook">
        <i class="fab fa-facebook-f"></i>
      </a> -->
      <!-- <a href="https://twitter.com" target="_blank" class="social-button twitter">
        <i class="fab fa-x"></i>
      </a> -->
      <a href="tel:+254799472096" aria-label="+254799472096" target="_blank" rel="noopener noreferrer" class="social-button call">
        <i class="fas fa-phone"></i>
      </a>
      <a href="https://wa.me/254799472096?text=https://projects.campussoko.co.ke/neatfreaks/ Tell%20me%20more%20about%20**NeatFreaks**%20services"
       target="_blank" class="social-button whatsapp" rel="noopener noreferrer">
  <i class="fab fa-whatsapp"></i>
</a>

    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .social-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: #000000;
    text-decoration: none;
    font-size: 24px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .social-button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .facebook {
    background-color: #3b5998;
  }
  
  .twitter {
    background-color: #1da1f2;
  }
  
  .instagram {
    background-color: #e1306c;
  }
  
  .whatsapp {
    background-color: #25d366;
  }
  </style>
  