<template>
    <footer class="footer" aria-label="Footer with contact information and map location">
      <!-- Google Map in Satellite View with Spinner -->
      <div class="footer-map" aria-labelledby="map-location">
        <h3 id="map-location" class="sr-only">Our Location on the Map</h3>
  
        <!-- Spinner -->
        <!-- <div v-if="isMapLoading" class="spinner" aria-hidden="true"></div>
        <span v-if="isMapLoading">Loading map</span> -->
  
        <!-- Google Map -->
        <!-- <iframe
        :src="mapSrc"
        width="100%"
        :height="mapHeight"
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        loading="lazy"
        @load="mapLoaded"
        referrerpolicy="no-referrer-when-downgrade"
        title="Neatfreaks Cleaning Solutions location on Google Maps"
      ></iframe> -->
      </div>
  
      <!-- Footer Container -->
       <div class="footer-into">
      <div class="footer-container">
        <!-- Branding -->
        <div class="footer-brand" aria-labelledby="company-info">
          
            <div class="brand-footer">
              <img class="footer-image" 
            src="@/assets/img/logo-icon-transparent.png" 
            alt="Neatfreaks Cleaning Solutions Logo" 
            loading="lazy"/>   
            <h2 class="company-info">
                 Neatfreaks Cleaning <span id="end"> Solutions</span>
            </h2>
            </div>
         
        </div>
  
        <!-- Contact Information -->
        <address class="footer-info" aria-labelledby="contact-details">
          <div class="footer-item" aria-label="Company address">
            <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
            <div>
              <p id="contact-details">Kilimani, Ngong' Road
              </p>
              <p><strong>Nairobi, Kenya</strong></p>
            </div>
          </div>
          <div class="footer-item" aria-label="Company phone numbers">
            <i class="fas fa-phone" aria-hidden="true"></i>
            <div>
              <p>
                <a href="tel:+254799472096" aria-label="Call us at +254 799 472 096 or +254 755 921 381">+254 799 472 096 / +254 755 921 381</a>
                <!-- <a href="tel:+254755921381" aria-label="Call us at +254 755 921 381">+254 755 921 381</a> -->
              </p>
            </div>
          </div>
          <div class="footer-item" aria-label="Email us at info@neatfreaks.co.ke">
            <i class="fas fa-envelope" aria-hidden="true"></i>
            <div>
              <a href="mailto:info@neatfreaks.co.ke" aria-label="Send an email to info@neatfreaks.co.ke">info@neatfreaks.co.ke</a>
            </div>
          </div>
        </address>
      </div>
  <hr/>
      <p>Copyright © {{currentYear}} NEATFREAKS CLEANING SOLUTIONS</p>
      <br/>
      <br/>
  </div>
    </footer>
  </template>
  
  
  <script setup>
import { ref, onMounted } from 'vue';

// // Reactive properties using `ref`
// const isMapLoading = ref(true); // Show the spinner initially
// const mapHeight = ref(0);
// const mapSrc = ref(
//   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.560917806756!2d36.802092297265624!3d-1.2928237127188814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10f8549f293d%3A0x65681d9e8bf42396!2sBishop%20Magua%20Centre!5e0!3m2!1sen!2ske!4v1697800604317!5m2!1sen!2ske&maptype=satellite"
// );

// // Method to handle when the map is loaded
// const mapLoaded = () => {
//   isMapLoading.value = false; // Hide the spinner once the map has loaded
//   mapHeight.value = 280;
// };

// Current year for copyright
const currentYear = ref(new Date().getFullYear());

onMounted(() => {
  // Update the current year on mount
  currentYear.value = new Date().getFullYear();
});
</script>
  
  <style scoped>
  .footer {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
}
.footer-into{
    background: rgba(0, 0, 0, 0.5);
    padding-bottom: 30px;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.footer-brand {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.footer-info {
  flex: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.footer-item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.footer-item i {
  margin-right: 10px;
  font-size: 20px;
  color: #fff;
}

.footer-item a {
  text-decoration: none;
  color: #ffff;
}

.footer-item a:hover {
  text-decoration: underline;
}
.end{
    color: #008bca;
}
/* Map Styling */
.footer-map {
  margin-top: 20px;
  background-color: rgba(243, 243, 243, 0.5); 
  padding: 5px;
}

.footer-map iframe {
  width: 100%;
  border-radius: 10px;
}

/* Spinner styles */
.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  margin-top: 10px;
  height: 30px;
  animation: spin 0.5s linear infinite;
  margin: 0 auto;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.brand-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.footer-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
    padding: 20px;
  }
  .footer-image {
  width: 30px;
  height: 30px;
  margin-right: 1px;
}

  .footer-brand, .footer-info {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .footer-item a {
    display: block;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .footer-brand {
    font-size: 1.2rem;
  }

  .footer-item {
    flex-direction: column;
    text-align: center;
  }

  .footer-item a {
    margin-bottom: 5px;
  }
}

/* Screen Reader Only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

  </style>
  
